import React, { useEffect, useState } from "react";
import { auth } from "../../utils/Firebase";
import axios from "axios";
import { Navbar, Dropdown, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  convertAuthUserResponseToReact,
  isAuthenticated,
} from "../../utils/Auth";
import { isPaid } from "../../utils/Valid";
import Example from "../burger/HamBurger";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { messaging } from "../../utils/Firebase";
import { useSelector, useDispatch } from "react-redux";
import {
  AddNotification,
  RemoveNotification,
  NotificationRead,
} from "../../redux/reducers/notificationSlice";
import { onMessage, isSupported } from "firebase/messaging";
import moment from "moment";
// import { RegisterBeams } from "../../utils/utills";
import { BeamsClientInstance } from "./reports/Reports";
import { getFcmToken } from "../../utils/generateFCM";

let user = {};
const NavbarDashboard = ({ isAdmin }) => {
  const [MobileCheck, setMobileCheck] = useState(false);
  const IsRead = useSelector((state) => state.notification.isRead);
  // const beamsClient = RegisterBeams()
  const [userStoredData, setUserStoredData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.innerWidth < 900) {
      const isMobile = window.innerWidth < 900;
      if (isMobile !== MobileCheck) {
        setMobileCheck(isMobile);
      }
    }
    window.addEventListener("resize", () => {
      const isMobile = window.innerWidth < 900;
      if (isMobile !== MobileCheck) {
        setMobileCheck(isMobile);
      }
    });
    const notificationSession = window.sessionStorage.getItem(
      "notificationMessage"
    );
    if (notificationSession === null) {
      isSupported().then((res) => {
        window.sessionStorage.setItem("notificationMessage", true);
      });
    }
  }, [MobileCheck]);
  const myNotification = useSelector(
    (state) => state.notification.notification
  );
  // const myNotification = []
  onMessage(messaging, (payload) => {
    if (payload.data.body) {
      dispatch(
        AddNotification({
          type: "report",
          body: payload.data.body,
          client_name: payload.data.client_name,
          time: new Date().toISOString(),
        })
      );
      toast.success(payload.data.title, { theme: "dark" });
    }
    if (payload.data.title.search("email") >= 0) {
      dispatch(
        AddNotification({
          type: "email",
          client_name: payload.data.client_name,
          time: new Date().toISOString(),
        })
      );
      toast.success(payload.data.title, { theme: "dark" });
    }
  });
  isAuthenticated();
  isPaid();

  var Admin_Token = localStorage.getItem("Stoken");
  const location = useLocation();
  const pathname = location.pathname;

  // let userStoredData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    const fcm_token = localStorage.getItem("fcm_token");
    if (fcm_token === undefined || fcm_token === null) {
      getFcmToken();
    }
    const getAuthUserApi = () => {
      let token = localStorage.getItem("access_token");
      var getUser = {
        method: "get",
        url: "/v1/oauth/user/get-user",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(getUser)
        .then(function (response) {
          user = response.data.user;
          user = convertAuthUserResponseToReact(user);
          localStorage.setItem("userData", JSON.stringify(user));
          setUserStoredData(user);
          if (user.is_payment_done !== 1) {
            window.location.href = "/plans";
          } else {
            localStorage.setItem("paymentStatus", true);
            if (response.data.user.user_type_id === 1) {
              window.location.href = "/admin";
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            window.location.href = "/login";
          }
        });
    };
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (
      !storedUserData ||
      storedUserData.created_at === undefined ||
      storedUserData.id === undefined
    ) {
      getAuthUserApi();
    } else {
      setUserStoredData(storedUserData); // Set the state with stored data
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    if (BeamsClientInstance) {
      BeamsClientInstance.stop()
        .then(() => console.log("Beams SDK stop"))
        .catch((err) => console.log("No Beams Client Found", err));
    }
    let token = localStorage.getItem("access_token");

    var config = {
      method: "post",
      url: "/v1/oauth/user/logout",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      });
  };

  const removeNotification = (payload) => {
    dispatch(RemoveNotification(payload));
  };

  return (
    <>
      <Navbar
        fixed="top"
        className="justify-content-between navbar-two"
        expand="lg"
        variant="light"
        bg="white"
      >
        <div className="d-flex align-items-center nav-menu-container-parent">
          {MobileCheck ? <Example /> : null}
          {!isAdmin ? (
            <Link className="nav-logo navbar-brand" to="/">
              {" "}
              <h1 className="mb-0">
                <img
                  src="/assets/mainicon.png"
                  alt="main"
                  className="navbar-icon"
                />
              </h1>
            </Link>
          ) : (
            <img
              src="/assets/mainicon.png"
              alt="main"
              className="navbar-icon"
            />
          )}
          {MobileCheck || isAdmin ? null : (
            <ul className="list-unstyled nav-menu-container">
              <li>
                <Link
                  to="/dashboard"
                  className={
                    pathname === "/dashboard"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/clients"
                  className={
                    pathname === "/clients"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Client
                </Link>
              </li>
              <li>
                <Link
                  to="/inspection"
                  className={
                    pathname === "/inspection"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Inspection
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/reports",
                    state: { text: "text" },
                  }}
                  className={
                    pathname === "/reports"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Report
                </Link>
              </li>
              <li>
                <Link
                  to="/reports-history"
                  className={
                    pathname === "/reports-history"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Report Archives
                </Link>
              </li>
              <li>
                <Link
                  to="/mail-logs"
                  className={
                    pathname === "/mail-logs"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Email Logs
                </Link>
              </li>
              <li>
                <Link
                  to="/templates"
                  className={
                    pathname === "/templates"
                      ? "active-nav nav-links"
                      : "nav-links"
                  }
                >
                  Templates
                </Link>
              </li>
              {Admin_Token && (
                <li>
                  <Link
                    onClick={() => {
                      const Admin_Token = localStorage.getItem("Stoken");
                      const Admin_data = localStorage.getItem("adminData");
                      window.localStorage.setItem("userData", Admin_data);
                      localStorage.setItem("access_token", Admin_Token);
                      localStorage.setItem("paymentStatus", true);
                      window.location.href = "/admin";
                    }}
                    style={{ color: "red" }}
                    className="nav-links"
                  >
                    <img
                      src="/assets/icon/admin.png"
                      style={{ height: 25 }}
                      alt="admin icon"
                    />
                  </Link>
                </li>
              )}
            </ul>
          )}
        </div>
        {!isAdmin && (
          <Dropdown onClick={() => dispatch(NotificationRead(true))}>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="profile-dropdown d-flex align-items-center"
            >
              <img
                alt="noitification"
                src={`/assets/icon/navbar/${
                  myNotification.length > 0 && !IsRead
                    ? "notification.png"
                    : "bell.png"
                }`}
                style={{ height: 25, width: 25, transform: "translateX(60%)" }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu size="xl" className="notification-dropdown">
              {myNotification.length > 0 ? (
                myNotification.map((element, index) => {
                  return (
                    <Dropdown.Item key={index}>
                      <Row
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          xs={10}
                          onClick={() => {
                            if (element.type === "report") {
                              window.open(element.body);
                            }
                          }}
                        >
                          <Row>
                            {element.type === "report" ? (
                              <h6 className="notification-text">
                                <strong>{`${element.client_name}`}</strong>
                                {` report is generated. Click to View!`}
                              </h6>
                            ) : (
                              <h6 className="notification-text">
                                <strong>{`${element.client_name}`}</strong>
                                {` report sent via email`}
                              </h6>
                            )}
                          </Row>
                          <Row>
                            <p style={{ textAlign: "start", color: "#8F9198" }}>
                              {moment(element.time).fromNow()}
                            </p>
                          </Row>
                        </Col>
                        <Col
                          xs={2}
                          style={{ display: "contents" }}
                          onClick={() => {
                            removeNotification(element.body);
                          }}
                        >
                          <img
                            src="/assets/icon/navbar/cancel.png"
                            alt="remove button"
                            style={{ height: 20, width: 20, marginTop: 10 }}
                          />
                        </Col>
                      </Row>
                    </Dropdown.Item>
                  );
                })
              ) : (
                <div>Notifications are empty!</div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown>
          <div className="profile-container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="mb-0 ellipsis text-capitalize profile-text d-inline-block text-truncate">
                <div>
                  {userStoredData ? userStoredData.first_name : ""}{" "}
                  {userStoredData ? userStoredData.last_name : ""}
                </div>
              </span>
            </div>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="profile-dropdown d-flex align-items-center"
            >
              <div className="profile-img-ct">
                <img
                  src={
                    userStoredData?.profile_image
                      ? userStoredData.profile_image
                      : "/assets/defaultProfile.png"
                  }
                  className="profile-img"
                  alt=""
                />
              </div>
              <div className="arrow down"></div>
            </Dropdown.Toggle>
          </div>

          <Dropdown.Menu className="profile-dpMenu">
            {!isAdmin && (
              <>
                <Link to="/my-account" className="dropdown-item">
                  My Profile
                </Link>
                <Link to="/plans" className="dropdown-item">
                  Billing
                </Link>
                <Link
                  to="/my-account/change-password"
                  className="dropdown-item"
                >
                  Change Password
                </Link>
              </>
            )}
            <Dropdown.Item href="" onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
    </>
  );
};

export default NavbarDashboard;
