import React from "react";

const Header = () => {
  return (
    <React.Fragment>
      <div className="banner-text-container" id="home">
        <div className="header-left-text">
          <p>
            {" "}
            <strong> Fastest & Easiest </strong> Home Inspection Software
          </p>
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.cybussolutions.hititpro"
              )
            }
            style={{ cursor: "pointer" }}
            src="/assets/pronspect/google.png"
            alt="google play"
          />
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/pronspect/id1584339246"
              )
            }
            style={{ cursor: "pointer" }}
            src="/assets/pronspect/apple.png"
            alt="ios"
          />
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/main.png"
          alt="header-img"
        />
      </div>
      {/* <div className="form-center mt140 d-flex justify-content-center">
                <form action="" className="text-center mt140">
                    <a href='https://www.facebook.com/groups/1167039177386471/people/?should_open_welcome_member_composer=1' target="_blank" rel="noreferrer" className="btn btn-facebook" >
                        <svg style={{ marginRight: 10 }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                        Connect on Facebook</a>
                </form>
            </div> */}

      <div className="banner-text-container-left" id="home">
        <div className="header-right-text">
          <p>
            {" "}
            <strong> User Friendly Report Generation </strong>{" "}
          </p>
          <span>
            {" "}
            Predefined descriptions, observations, recommendations and comments
            for eleven different components of the inspection. Multiple
            opportunities to edit text, images and recommendations.{" "}
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature1.png"
          alt="header-img"
        />
      </div>
      <div className="banner-path">
        <img
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            top: 1100,
          }}
          src="/assets/pronspect/path.png"
          alt="path"
        />
      </div>
      <div className="banner-text-container" id="home">
        <div className="header-left-text">
          <p>
            {" "}
            <strong> Customer Friendly Report Generation </strong>{" "}
          </p>
          <span>
            Provides your customer with a professionally formatted report that
            they can follow in a logical sequence. A summary of findings and
            recommendations is provided at the beginning of the report for quick
            reference. Detailed comments and images are listed by section in an
            easy to read and understand format.
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature2.png"
          alt="header-img"
        />
      </div>
      <div className="banner-text-container-left" id="home">
        <div className="header-right-text">
          <p>
            {" "}
            <strong> Cloud Based Data Management </strong>{" "}
          </p>
          <span>
            No software to download freeing up disk space. Updates and
            improvements are automatically done with no action required by you.
            All client report information is stored for both active and archived
            clients allowing instant access.
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature3.png"
          alt="header-img"
        />
      </div>
      <div className="banner-text-container" id="home">
        <div className="header-left-text">
          <p>
            {" "}
            <strong> Automatic Monthly or Annual Billing </strong>{" "}
          </p>
          <span>
            After creation of your account your monthly service fee will be
            automatically deducted from your credit/debit card. No further
            action is required on your part.
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature4.png"
          alt="header-img"
        />
      </div>
      <div className="banner-text-container-left" id="home">
        <div className="header-right-text">
          <p>
            {" "}
            <strong> Simple 3 Step Process </strong>{" "}
          </p>
          <span>
            Once you enter your client information into the system and perform
            the inspection there are 3 simple steps to complete the report.
            First, enter the inspection data into the predefined, fully
            editable, forms. Second, generate an Inspection Review to review and
            make any final changes/edits. Finally, point and click to generate
            the customers report in PDF format and email it to them.
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature5.png"
          alt="header-img"
        />
      </div>
      <div className="banner-text-container" id="home">
        <div className="header-left-text">
          <p>
            {" "}
            <strong> Save Time </strong>{" "}
          </p>
          <span>
            ProNspect was developed with the sole purpose of saving you time.
            Time you can use to complete additional inspections, spend with your
            family, enjoy your hobbies or anything else YOU choose. From the
            simplified input to the simple editing methods to the finished
            product review and edit to the automated billing everything is about
            saving you time. It’s your life so don’t spend one minute you don’t
            need to generating reports. Enjoy life some!
          </span>
          {/* <a href="/#">Request Demo <img className="p-2" src="/assets/arrow-fwd-sm.png" alt="arrow-fwd" /> </a> */}
        </div>
        <img
          className="header-img "
          src="/assets/pronspect/feature6.png"
          alt="header-img"
        />
      </div>
      <div className="form-center mt140 mb140">
        <form action="" className="text-center mt140">
          <a
            href="https://www.youtube.com/channel/UCfBr3EICD7iKn8hTmw3ZSaQ/videos"
            target="_blank"
            rel="noreferrer"
            className="btn btn-youtube"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              style={{ fill: "#000000" }}
            >
              <path
                fill="#FF3D00"
                d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
              ></path>
              <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
            </svg>
            Instructional and Informational Videos
          </a>
        </form>
      </div>
      <div></div>
    </React.Fragment>
  );
};

export default Header;
