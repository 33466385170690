/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import ToastUI from "../../../../../utils/ToastUI";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Resizer from "react-image-file-resizer";

const ViewPopup = (props) => {
  const {
    show,
    handleClose,
    tabName,
    data,
    handlePopupCommentImageApi,
    handleChanges,
    source,
  } = props;

  const { label, checked } = data.values;
  const { comment: commentData, selrecomd } = data.resData;
  const [comment, setComment] = useState();
  const [radioChecked, setRadioChecked] = useState();
  const [error, setError] = useState(false);
  const [msgImgError, setmsgImgError] = useState(false);
  const { type } = data.values;
  const [price, setPrice] = useState(null);
  let fetchImages = data.resData.images;

  //canvas related
  const [showImage, setShowImage] = useState([]);
  const [newImage, setnewImage] = useState();

  const [editImage, setEditImage] = useState();
  const [imageIndex, setIndex] = useState("");
  const [uploadImages, setImages] = useState([]);

  var ImageUrl = [];

  const myImage = (url, index) => {
    let imagefile = [];
    if (url) {
      var dataSource = url.substring(0, 4);
    } else {
      dataSource = "";
    }
    if (dataSource === "data") {
      ImageUrl.push(url);
      setShowImage(ImageUrl);
      const imageFile = dataURItoBlob(url);
      setnewImage(imageFile);
    } else {
      ImageUrl.push(url);
      setShowImage(ImageUrl);
      fetch(url, { mode: "cors" })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const contentType = response.headers.get("content-type");
          return response.blob();
        })
        .then((blob) => {
          const file = new File([blob], "myFile1.png", { type: blob.type });
          setnewImage(file);
        })
        .catch((error) => {
          console.error(
            "There was a problem with the fetch operation:",
            error.message
          );
          // Handle the error gracefully, e.g., use a placeholder image
          const placeholderUrl = "/path/to/placeholder-image.jpg";
          ImageUrl[ImageUrl.length - 1] = placeholderUrl;
          setShowImage([...ImageUrl]);
        });
    }

    return imagefile;
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = window.atob(dataURI.split(",")[1]);
    else byteString = decodeURI(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  function fetchAllImages() {
    document.body.classList.add("loading-indicator");
    fetchImages.forEach((image, index) => myImage(image, index));
    if (fetchImages.length > 0) {
      if (uploadImages.length === fetchImages.length) {
        document.body.classList.remove("loading-indicator");
      }
    } else {
      document.body.classList.remove("loading-indicator");
    }
    setTimeout(() => {
      document.body.classList.remove("loading-indicator");
    }, 3000);
  }

  useEffect(() => {
    if (newImage) {
      setImages([...uploadImages, newImage]);
    }
    // eslint-disable-next-line
  }, [newImage]);

  useEffect(() => {
    setComment(commentData);
    console.log(data.resData);
    setPrice(data.resData.price);
    setRadioChecked(selrecomd);
    fetchAllImages();

    // eslint-disable-next-line
  }, []);

  const values = {
    label,
    checked,
    radioChecked,
    comment,
    uploadImages,
    price,
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setmsgImgError(false);
  };
  const handleSubmit = (submitData, images) => {
    if (
      submitData.comment.length === 0 &&
      submitData.uploadImages.length === 0
    ) {
      setmsgImgError(true);
      return;
    }
    if (!radioChecked && type === "recommendation") {
      setError(true);
      return;
    }
    handlePopupCommentImageApi(submitData, data.values);
    if (source === "review") {
      const imagesArray = images.map((img) => ({
        url: img,
        id: Math.floor(Math.random() * 1000),
      }));
      handleChanges(submitData, data, imagesArray, type);
    }
  };
  const handleRadioChange = (e) => {
    setRadioChecked(e.target.value);
    setError(false);
  };
  const fileChangedHandler = (event) => {
    var fileInput = false;
    const tempArr = [...uploadImages];
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        [...event.target.files].forEach((file) => {
          Resizer.imageFileResizer(
            file,
            3000,
            3000,
            "JPEG",
            20,
            0,
            (uri) => {
              const imageFile = dataURItoBlob(uri);
              tempArr.push(imageFile);
              // setnewImage(imageFile);
              // this.setState({ newImage: uri });
            },
            "base64",
            200,
            200
          );
        });
      } catch (err) {}
    }

    setImages(tempArr);
  };

  const handleImageUpload = (e) => {
    const tempArr = [...showImage];

    [...e.target.files].forEach((file) => {
      tempArr.push(URL.createObjectURL(file));
    });

    setShowImage(tempArr);
  };

  const handlePageCover = (e) => {
    document.body.classList.add("loading-indicator");

    fileChangedHandler(e);

    if (e.target.files[0]) {
      handleImageUpload(e);
    }
    setmsgImgError(false);
    document.body.classList.remove("loading-indicator");
  };

  const setCanvasImage = (e, index) => {
    console.log("index", showImage[index]);
    setEditImage(showImage[index]);
    setIndex(index);
  };

  const saveCanvasImage = () => {
    const elem = document.getElementsByClassName("lower-canvas");
    var dataURL = elem[0].toDataURL("image/jpeg", 0.1);
    var blob = dataURItoBlob(dataURL);

    //upload images change
    const uploadArray = [...uploadImages];
    uploadArray.splice(imageIndex, 1);
    setImages(uploadArray);

    //show images change
    const newArray = [...showImage];
    newArray.splice(imageIndex, 1);

    setnewImage(blob);
    setEditImage(null);
    setShowImage([...newArray, dataURL]);
  };

  const deleteImage = (e, delindex) => {
    if (delindex === imageIndex) {
      setCanvasImage(null);
    }
    const images = [...showImage];
    images.splice(delindex, 1);
    const sentImages = [...uploadImages];
    sentImages.splice(delindex, 1);
    setShowImage(images);
    setImages(sentImages);
  };

  const checkRadio = () => {
    if (type === "recommendation")
      if (!radioChecked) {
        setError(true);
      }
  };

  let settings = {
    dots: true,
    arrows: true,
    scale: 0.8,
    infinite: false,
    indicators: true,
    draggable: true,
    focusOnSelect: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    // slidesToShow: showImage.length < 5 ? showImage.length : 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Modal
        enforceFocus={false}
        size="xl"
        show={show}
        onHide={handleClose}
        className="custom-Modal"
      >
        <Modal.Header
          closeButton
          className="pl0 pr0 text-transform-capitalize pb-2"
        >
          <h4 className="mb-0">{type} Comment</h4>
        </Modal.Header>
        <Modal.Body className="pt20 pb20">
          <Row className="mb-2">
            <Col md={7} className="pl0">
              <Form.Control
                as="textarea"
                rows={8}
                value={comment}
                onChange={(e) => handleCommentChange(e)}
              />
              {msgImgError && (
                <small style={{ color: "red" }}>
                  Please select an image or add a comment
                </small>
              )}
            </Col>
            <Col md={5} className="pr0">
              {type === "recommendation" ? (
                <>
                  <h5>Select Recommendation</h5>
                  <ul className="list-unstyled pl-0">
                    <li>
                      <label htmlFor="concern">
                        <input
                          type="radio"
                          name="select_recommendation"
                          value="Concern"
                          onChange={(e) => handleRadioChange(e)}
                          id="concern"
                          checked={radioChecked === "Concern" ? "checked" : ""}
                        />{" "}
                        &nbsp; Concern
                      </label>
                    </li>
                    <li>
                      <label htmlFor="safety">
                        <input
                          type="radio"
                          name="select_recommendation"
                          value="Safety issue"
                          onChange={(e) => handleRadioChange(e)}
                          id="safety"
                          checked={
                            radioChecked === "Safety issue" ? "checked" : ""
                          }
                        />{" "}
                        &nbsp; Safety Issue
                      </label>
                    </li>
                    <li>
                      <label htmlFor="repair">
                        <input
                          type="radio"
                          name="select_recommendation"
                          value="Repair"
                          onChange={(e) => handleRadioChange(e)}
                          id="repair"
                          checked={radioChecked === "Repair" ? "checked" : ""}
                        />{" "}
                        &nbsp; Repair
                      </label>
                    </li>
                    <li>
                      <label htmlFor="improve">
                        <input
                          type="radio"
                          name="select_recommendation"
                          value="Improve"
                          onChange={(e) => handleRadioChange(e)}
                          id="improve"
                          checked={radioChecked === "Improve" ? "checked" : ""}
                        />{" "}
                        &nbsp; Improve
                      </label>
                    </li>
                    <li>
                      <label htmlFor="monitor">
                        <input
                          type="radio"
                          name="select_recommendation"
                          value="Monitor"
                          onChange={(e) => handleRadioChange(e)}
                          id="monitor"
                          checked={radioChecked === "Monitor" ? "checked" : ""}
                        />{" "}
                        &nbsp; Monitor
                      </label>
                    </li>
                  </ul>

                  {error && (
                    <small style={{ color: "red" }}>
                      Please select an option from above
                    </small>
                  )}
                  <div className="my-2">
                    <h5>Price</h5>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          fontSize: "16px",
                        }}
                      >
                        $
                      </span>
                      <input
                        type="number"
                        style={{
                          borderRadius: "8px",
                          paddingLeft: "25px",
                          width: "100%",
                        }}
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d*\.?\d{0,2}$/;
                          if (regex.test(value) || value === "") {
                            setPrice(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="input-custom form-group">
                <label className="upl-ct">
                  <input
                    type="file"
                    className="form-control"
                    name="profile_image"
                    id="profileLogo"
                    accept="image/*"
                    multiple
                    onClick={checkRadio}
                    onInputCapture={(e) => {
                      handlePageCover(e);
                      setTimeout(() => {
                        e.target.value = "";
                      }, 1000);
                    }}
                  />
                  + <br />
                  Upload Image
                </label>
              </div>

              <div className="input-custom form-group">
                <label className="upl-ct">
                  <input
                    type="file"
                    className="form-control"
                    name="camera_image"
                    id="cameraLogo"
                    accept="image/*"
                    capture="environment" // This attribute opens the back camera
                    onClick={checkRadio}
                    onInputCapture={(e) => {
                      handlePageCover(e);
                      setTimeout(() => {
                        e.target.value = "";
                      }, 1000);
                    }}
                  />
                  <img
                    src="/cameraa.png" // Replace with the path to your camera icon
                    alt="Camera Icon"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <br />
                  Capture Image
                </label>
              </div>
            </Col>
          </Row>
          {showImage && (
            <div>
              <Slider {...settings}>
                {showImage.map((imageSrc, index) => (
                  <div key={index}>
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        position: "relative",
                      }}
                    >
                      <img
                        id="imageid"
                        alt="canvas images"
                        // onClick={(e) => setCanvasImage(e, index)}
                        src={imageSrc}
                        // crossOrigin="Anonymous"
                        style={{ height: 200, width: 200 }}
                      />
                      <img
                        src="/assets/icon/edit-prof.png"
                        onClick={(e) => setCanvasImage(e, index)}
                        style={{
                          // zIndex: "20px",
                          position: "relative",
                          top: "-200px",
                          right: "-70px",
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                        }}
                        alt="edit"
                      />
                      <img
                        src="/assets/delete.png"
                        onClick={(e) => deleteImage(e, index)}
                        style={{
                          // zIndex: "20px",
                          position: "relative",
                          top: "-235px",
                          right: "40px",
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                        }}
                        alt="delete"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {editImage && (
            <>
              <Row>
                <ToastUI key={imageIndex} source={editImage} />
                <div className="col-md-6 mt20 pl0">
                  <Button
                    className="m-0 w-100 btn-success"
                    onClick={saveCanvasImage}
                  >
                    Save Changes
                  </Button>
                </div>
                <div className="col-md-6 mt20 pr0">
                  <Button
                    variant="dark"
                    className="w-100 "
                    onClick={() => {
                      setEditImage(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </>
          )}
        </Modal.Body>

        {!editImage && (
          <Modal.Footer className="row pl0 pr0">
            <div className="col-md-6 m-0 pl0">
              <Button
                className="m-0 w-100 btn-submit "
                disabled={msgImgError ? true : error ? true : false}
                onClick={() => handleSubmit(values, showImage)}
              >
                Save
              </Button>
            </div>
            <div className="col-md-6 m-0 pr0">
              <Button
                variant="secondary"
                className="w-100 "
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ViewPopup;
