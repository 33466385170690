import axios from "axios";
export const dispatchLogin = ({ credential, user }) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("email", user.reloadUserInfo.providerUserInfo[0].email);
  data.append("provider", "google");
  data.append("providerId", user.providerData[0].uid);

  // Split the display name into first and last name
  const fullName = user.displayName || "";
  const nameParts = fullName.split(" ");
  const firstName = nameParts[0] || "";
  const lastName = nameParts.slice(1).join(" ") || "";

  data.append("firstName", firstName);
  data.append("lastName", lastName);
  axios({
    method: "post",
    url: "/registerSocial",
    data: data,
  })
    .then(function (response) {
      if (response.data.token !== undefined) {
        localStorage.setItem("access_token", response.data.token);
        window.location.href = "/dashboard";
      }
    })
    .catch(function (error) {
      if (error.request.status === 400) {
      }
    });
};
