import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "../../../../utils/Firebase";
import { dispatchLogin } from "./GoogleLogic";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.addScope("profile");
provider.addScope("email");

export const googleLogin = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const user = result.user;

    dispatchLogin({ credential, user });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData?.email;
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.error("Google sign-in error:", {
      errorCode,
      errorMessage,
      email,
      credential,
    });
    // Handle the error appropriately (e.g., show an error message to the user)
  }
};
