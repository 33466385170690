import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { auth } from "../../../utils/Firebase";
import ScrollToTop from "../../ScrollToTop";
import { FacebookAuth } from "./Socials/Facebook";
import { googleLogin } from "./Socials/Google";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

const StepOne = ({
  data,
  updateFormData,
  next,
  showIcons,
  formFor,
  handleEmailValidation,
  handleAvailablitity,
}) => {
  // console.log(data, 'data');
  // console.log(next);
  const [mynumber, setnumber] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [showPhoneInputCt, setPhoneInputCt] = useState(true);
  const [numberError, setNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [clicked, setClicked] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    if (show === true) {
      setshow(false);
      setPhoneInputCt(true);
      setnumber("");
    }
    if (!userPhoneNumber) {
      setShowModal(true);
    }
  };
  // OTP Phone Number Authentication
  const signin = () => {
    console.log("here");
    if (mynumber === "" || mynumber.length < 10) {
      if (mynumber === "") {
        setNumberError("Enter Number to Continue");
        return;
      }
      if (mynumber.length < 10) {
        setNumberError("Enter Valid Number");
        return;
      }
    }
    setNumberError(null);
    console.log(window);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      auth
    );
    var appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, appVerifier)
      .then((confirmationResult) => {
        setfinal(confirmationResult);
        if (confirmationResult) {
          setshow(true);
          setPhoneInputCt(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === "" || final === "") {
      setOtpError("Enter Valid OTP code.");
      return;
    }
    final
      .confirm(otp)
      .then((result) => {
        if (result) {
          setUserPhoneNumber(result.user.phoneNumber);
          updateFormData("step1", "phone_number", result.user.phoneNumber);
          setPhoneInputCt(true);
          setshow(false);
          handleClose();
        }
        // success
      })
      .catch((err) => {
        // alert("Wrong code");
        setOtpError("Wrong OTP code.");
      });
  };

  const validate = Yup.object({
    firstName: Yup.string().required("Enter first name"),
    lastName: Yup.string().required("Enter last name"),
    email: Yup.string().email("Invalid Email").required("Enter Email"),
    password: Yup.string()
      .required("Enter Password")
      .min(8, "Password should be atleast 8 characters"),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    phone_number: Yup.number().required("Enter Phone Number"),
  });
  const validateUpdate = Yup.object({
    firstName: Yup.string().required("Enter first name"),
    lastName: Yup.string().required("Enter last name"),
    email: Yup.string().email("Invalid Email").required("Enter Email"),
  });

  const submitForm = (values) => {
    // console.log(values, 'lets move to next step');
    next();
  };

  return (
    <>
      <ScrollToTop />
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={
          formFor === "registration" ? validate : validateUpdate
        }
        onSubmit={submitForm}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
          } = props;

          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              {showIcons ? (
                <div className="signUp-social-icons-container">
                  <div
                    className="signup-icon-container"
                    style={{
                      backgroundColor: "orange",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() => googleLogin()}
                  >
                    <img
                      src="/assets/white-google.png"
                      alt=""
                      style={{ cursor: "pointer" }}
                    />{" "}
                    Google
                  </div>
                  {/* <div
                    className="signup-icon-container"
                    style={{ backgroundColor: "#3b5998", cursor: "pointer" }}
                    onClick={() => FacebookAuth()}
                  >
                    <img src="/assets/white-facebook.png" alt="" />
                  </div> */}
                </div>
              ) : null}
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, firstName: true })}
                  type="text"
                  name="firstName"
                  value={values?.firstName || ""}
                  className="form-control "
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // let value = e.target.value;
                    // value = value.replace(/[^A-Za-z ]/gi, "");
                    // let newVal = e;
                    // newVal.target.value = value;
                    handleChange(e);
                    updateFormData("step1", e.target.name, e.target.value);
                  }}
                  placeholder="First Name *"
                  autoComplete="off"
                />
                {errors.firstName && touched.firstName && (
                  <small className="input-error">{errors.firstName}</small>
                )}
              </div>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, lastName: true })}
                  type="text"
                  name="lastName"
                  value={values?.lastName || ""}
                  className="form-control "
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // let value = e.target.value;
                    // value = value.replace(/[^A-Za-z ]/gi, "");
                    handleChange(e);
                    updateFormData("step1", e.target.name, e.target.value);
                  }}
                  placeholder="Last Name *"
                  autoComplete="off"
                />
                {errors.lastName && touched.lastName && (
                  <small className="input-error">{errors.lastName}</small>
                )}
              </div>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, email: true })}
                  type="email"
                  name="email"
                  value={values?.email || ""}
                  // validate={validateEmail}

                  autoComplete="no-fill"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleEmailValidation(e.target.value);
                    handleChange(e);
                    updateFormData("step1", e.target.name, e.target.value);
                  }}
                  className="form-control "
                  placeholder="Email *"
                />
                {errors.email && touched.email && (
                  <small className="input-error">{errors.email}</small>
                )}
                {!handleAvailablitity && !errors.email && (
                  <small style={{ color: "red" }}>Email is Already Taken</small>
                )}
              </div>
              {formFor === "registration" ? (
                <>
                  <div className="input-custom form-group">
                    <input
                      type="password"
                      name="password"
                      value={values?.password || ""}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        updateFormData("step1", e.target.name, e.target.value);
                      }}
                      className="form-control "
                      autoComplete="new-password"
                      placeholder="Create Strong Password *"
                    />
                    {errors.password && touched.password && (
                      <small className="input-error">{errors.password}</small>
                    )}
                  </div>
                  <div className="input-custom form-group">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={values?.confirmPassword || ""}
                      onBlur={handleBlur}
                      className="form-control "
                      onChange={(e) => {
                        handleChange(e);
                        updateFormData("step1", e.target.name, e.target.value);
                      }}
                      autoComplete="new-password"
                      placeholder="Repeat Password *"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <small className="input-error">
                        {errors.confirmPassword}
                      </small>
                    )}
                  </div>
                </>
              ) : null}
              <div className="input-custom form-group">
                <input
                  type="text"
                  name="phone_number"
                  onClick={handleShow}
                  readOnly
                  value={values?.phone_number || ""}
                  className="form-control "
                  autoComplete="enter-number"
                  placeholder="Enter Number *"
                />
                {errors.phone_number && touched.phone_number && (
                  <small className="input-error">{errors.phone_number}</small>
                )}
              </div>

              <br />
              <button
                type="submit"
                className="btn btn-submit "
                disabled={handleAvailablitity ? false : true}
              >
                Continue <i className="fa fa-arrow-right"></i>{" "}
              </button>

              <Modal show={showModal} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Phone Number Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  <div
                    className={
                      showPhoneInputCt === false ? "phone-input-ct" : ""
                    }
                  >
                    <div className="input-custom form-group mt-5">
                      <PhoneInput
                        placeholder="Enter phone number"
                        // countries={["US"]}
                        defaultCountry="US"
                        value={mynumber}
                        onChange={setnumber}
                      />

                      {numberError && (
                        <small className="input-error">{numberError}</small>
                      )}

                      <br />
                      <div id="recaptcha-container"></div>
                      <button
                        className="btn btn-light"
                        onClick={signin}
                        disabled={userPhoneNumber}
                      >
                        Send OTP
                      </button>
                    </div>
                    <br />
                  </div>

                  <div
                    className="input-custom form-group mt-5"
                    style={{ display: show ? "block" : "none" }}
                  >
                    <input
                      type="text"
                      className="form-control otp-input"
                      placeholder={"Enter your OTP"}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }}
                    ></input>
                    {otpError && (
                      <small className="input-error">{otpError}</small>
                    )}

                    <br />
                    <button className="btn btn-light" onClick={ValidateOtp}>
                      Verify
                    </button>
                    <br />
                  </div>
                </Modal.Body>
                {/* <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleClose}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer> */}
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default StepOne;
